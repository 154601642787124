import React from 'react'

const Privacy = () => {
  return (
    <div style={{ margin: 50 }}>
      <h1>นโยบายความเป็นส่วนตัว AIYA แชทบอท</h1>
      <h2>1. ข้อมูลเบื้องต้น</h2>
      <dd>
        1.1. บริษัท มีจีเนียส จำกัด (“บริษัท”)
        มีหน้าที่รับผิดชอบภายใต้กฎหมายและข้อบังคับความเป็นส่วนตัว รวมทั้งข้อมูลส่วนบุคคลที่บังคับใช้
        ตัวอย่างเช่น พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
        (“กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล”)
        และยึดมั่นต่อการเคารพสิทธิและให้ความสำคัญเกี่ยวกับความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้
        AIYA แชทบอท ทุกท่าน
        <br />
        1.2. บริษัทตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลของผู้ที่ได้ลงทะเบียน หรือเปิดบัญชี AIYA
        แชทบอท (“บัญชีผู้ใช้”) บริษัททราบดีว่าบริษัทมีหน้าที่ในการจัดการ ดูแล ปกป้อง
        และดำเนินการต่อข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม ดังนั้นนโยบายความเป็นส่วนตัวนี้
        (“นโยบายความเป็นส่วนตัว” หรือ “นโยบาย”)
        จึงได้จัดทำขึ้นเพื่อช่วยให้ท่านเข้าใจเกี่ยวกับวิธีที่บริษัท เก็บรวบรวม ใช้ เปิดเผย
        และ/หรือดำเนินการกับข้อมูลส่วนบุคคลที่ท่านมอบให้แก่บริษัท
        และ/หรือข้อมูลของท่านที่บริษัทได้จัดเก็บไว้ไม่ว่าจะในปัจจุบันหรืออนาคต
        รวมทั้งยังเป็นข้อมูลประกอบการตัดสินใจก่อนที่ท่านจะให้ข้อมูลส่วนตัวใด ๆ แก่บริษัท
        ทั้งนี้ทางบริษัทหวังเป็นอย่างยิ่งว่าท่านจะอ่านนโยบายความเป็นส่วนตัวนี้โดยละเอียด
        หากท่านมีข้อสงสัยเกี่ยวกับข้อมูลเหล่านี้หรือข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของบริษัท
        ท่านสามารถส่งอีเมลมาที่ hello@aiya.ai เพื่อสอบถามข้อมูลเพิ่มเติม
        <br />
        1.3. เพื่อให้เกิดความเข้าใจเกี่ยวกับนิยามของข้อมูลส่วนบุคคล ทางบริษัทได้กำหนดนิยามไว้ดังนี้
        “ข้อมูลส่วนบุคคล” หมายถึง
        ข้อมูลเกี่ยวกับบุคคลซึ่งอาจทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม อาทิ ชื่อ
        หมายเลขประจำตัว และข้อมูลการติดต่อ แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรม
        <br />
        1.4. การให้บริการแพลตฟอร์ม AIYA แชทบอท (“บริการ”) ในการใช้บริการ การลงทะเบียนบัญชีผู้ใช้
        และ/หรือเข้าถึงบริการใด ๆ ท่านได้รับทราบและตกลงว่าท่านยอมรับข้อปฏิบัติ ข้อกำหนด
        และ/หรือนโยบายที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้ และท่านยินยอมให้บริษัทเก็บรวบรวม ใช้
        เปิดเผย และ/หรือดำเนินการกับข้อมูลส่วนบุคคลของท่านดังที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
        หากท่านไม่ยินยอมให้บริษัทดำเนินการกับข้อมูลส่วนบุคคลของท่านดังที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
        โปรดหยุดการใช้บริการหรือหยุดการเข้าถึง AIYA แชทบอท หรือลบบัญชีผู้ใช้
        และแจ้งให้บริษัททราบในทันที หากนโยบายความเป็นส่วนตัวของบริษัทมีการเปลี่ยนแปลงรายละเอียดใด ๆ
        บริษัทจะแจ้งการเปลี่ยนแปลงดังกล่าวหรือนโยบายความเป็นส่วนตัวที่แก้ไขบนเว็บไซต์
        https://aiya.ai
        ทั้งนี้บริษัทขอสงวนสิทธิ์ในการแก้ไขนโยบายความเป็นส่วนตัวนี้โดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า
        แต่จะบังคับใช้กับท่านต่อเมื่อท่านได้ให้ความยินยอมกับนโยบายความเป็นส่วนตัวฉบับใหม่นั้นแล้ว
        (ในกรณีที่ต้องมีการขอความยินยอม)
        <br />
      </dd>
      <h2>2. การเก็บรวบรวมข้อมูลส่วนบุคคลของบริษัท</h2>
      <dd>
        2.1. บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่านในกรณีดังต่อไปนี้
        <dd>
          2.1.1. เมื่อท่านได้เข้าสู่เว็บไซต์ https://aiya.ai และ/หรือใช้บริการ AIYA แชทบอท ของบริษัท
          หรือลงทะเบียน หรือสมัครบัญชีผู้ใช้กับบริษัท
          <br />
          2.1.2. เมื่อท่านติดต่อกับบริษัท เช่น ผ่านทางโทรศัพท์ (ซึ่งอาจจะได้รับการบันทึก) จดหมาย
          แฟกซ์ การประชุมแบบเห็นหน้ากัน แพลตฟอร์มสื่อทางออนไลน์ และอีเมล
          <br />
          2.1.3. เมื่อท่านใช้บริการทางอิเล็กทรอนิกส์ หรือติดต่อกับบริษัทผ่านเว็บไซต์ https://aiya.ai
          หรือการใช้บริการ เช่น ผ่านการใช้คุกกี้
          <br />
          2.1.4. เมื่อท่านให้ความคิดเห็นหรือคำร้องเรียนแก่บริษัท
          <br />
          2.1.5.
          เมื่อท่านเข้าเยี่ยมชมหรือเข้าสู่เว็บไซต์หรือแอพพลิเคชั่นของหุ้นส่วนหรือบริษัทในเครือของบริษัท
          <br />
          2.1.6. เมื่อท่านเข้าเยี่ยมชมหรือเข้าสู่เว็บไซต์หรือแอพพลิเคชั่นของบุคคลภายนอกที่ใช้
          และ/หรือ เกี่ยวข้องกับบริการของบริษัท
          <br />
          2.1.7. เมื่อท่านส่งข้อมูลส่วนบุคคลของท่านให้แก่บริษัทด้วยเหตุผลใดก็ตาม
          ทั้งนี้ในกรณีที่ทางบริษัทพบว่าข้อมูลที่ทางบริษัทได้รับโดยไม่ได้มีการร้องขอ
          เป็นข้อมูลที่มีความเสี่ยงในการจัดเก็บ ทางบริษัทมีสิทธิในการลบหรือทำลายข้อมูลนั้นโดยทันที
        </dd>
        2.2. บริษัทอาจเก็บรวบรวม ใช้ เปิดเผย และ/หรือดำเนินการข้อมูลนี้เพื่อวัตถุประสงค์ต่าง ๆ
        ตามที่กำหนดไว้ในนโยบายความเป็นส่วนตัว หรือเงื่อนไขการใช้บริการ
      </dd>
      <h2>3. ข้อมูลที่ได้มีการเก็บรวบรวม</h2>
      <dd>
        3.1. ข้อมูลส่วนบุคคลที่บริษัทอาจเก็บรวบรวมมีรายการดังต่อไปนี้
        <dd>
          3.1.1. ชื่อ
          <br />
          3.1.2. อีเมล
          <br />
          3.1.3. ที่อยู่
          <br />
          3.1.4. หมายเลขติดต่อ
          <br />
          3.1.5. ที่อยู่อินเทอร์เน็ตโปรโตคอล (IP)
          <br />
          3.1.6. ข้อมูลอื่นใดเกี่ยวกับผู้ใช้เมื่อผู้ใช้ลงทะเบียนเข้าใช้บริการ AIYA แชทบอท ของบริษัท
          และเมื่อผู้ใช้ใช้บริการ AIYA แชทบอท รวมถึงข้อมูลที่เกี่ยวกับวิธีการใช้บริการ AIYA แชทบอท
          ของผู้ใช้
          <br />
          3.1.7. ข้อมูลของบุคคลทั่วไปและกลุ่มที่ท่านเชื่อมต่อหรืออาจเชื่อมต่อกันบนแพลตฟอร์มของบริษัท
          และลักษณะที่ท่านติดต่อสื่อสารกับบุคคลเหล่านั้น
          <br />
          3.1.8. ข้อมูลส่วนบุคคลที่ท่านอาจให้แก่บริษัทผ่านทาง AIYA แชทบอท
        </dd>
        3.2. ในบางกรณีบริษัทอาจได้รับข้อมูลเกี่ยวกับท่านและกิจกรรมของท่านทั้งที่อยู่บนแพลตฟอร์ม AIYA
        แชทบอทและไม่อยู่บนแพลตฟอร์ม AIYA แชทบอท จากบริษัทในเครือและ/หรือหุ้นส่วนซึ่งเป็นบุคคลภายนอก
        เช่น
        ข้อมูลจากบริษัทในเครือและ/หรือหุ้นส่วนที่บริษัทได้ร่วมกันเสนอบริการและ/หรือรายการส่งเสริมการขาย
        หรือจากผู้โฆษณาเกี่ยวกับประสบการณ์หรือการติดต่อของท่านกับบุคคลเหล่านั้น
        รวมถึงข้อมูลเกี่ยวกับเว็บไซต์ และแอพพลิเคชั่นที่ท่านเข้าเยี่ยมชม
        การที่ท่านใช้บริการของบริษัทบน AIYA แชทบอท และแอพพลิเคชั่นเหล่านั้น
        เช่นเดียวกับข้อมูลผู้พัฒนาหรือผู้เผยแพร่บนแอพพลิเคชั่นหรือเว็บไซต์ที่ให้แก่ท่านหรือบริษัท
        <br />
        3.3. หากท่านไม่ประสงค์ให้บริษัทเก็บรวบรวมข้อมูล/ข้อมูลส่วนบุคคลดังที่กล่าวมาข้างต้น
        ท่านอาจยกเลิกได้ตลอดเวลาผ่านทาง AIYA แชทบอท
        หรือโดยการแจ้งความประสงค์ให้เจ้าหน้าที่คุ้มครองข้อมูลของบริษัททราบเป็นลายลักษณ์อักษร
        ทั้งนี้ท่านสามารถอ่านข้อมูลเพิ่มเติมเกี่ยวกับการยกเลิกได้ที่ส่วนด้านล่าง อย่างไรก็ตาม
        การยกเลิกการเก็บรวบรวมข้อมูลส่วนบุคคลหรือถอนความยินยอมในการเก็บรวบรวม ใช้ เปิดเผย แบ่งปัน
        หรือดำเนินการข้อมูลส่วนบุคคลของท่านกับบริษัทนั้นอาจส่งผลต่อการใช้บริการของท่าน ตัวอย่างเช่น
        การยกเลิกการเก็บรวบรวมข้อมูลของท่านอาจทำให้การให้บริการของบริษัทแก่ท่านต้องหยุดลง
      </dd>

      <h2>4. ความเป็นส่วนตัวของบัญชีผู้ใช้ของท่าน</h2>
      <dd>
        4.1. ในการดัดแปลง แก้ไข หรือควบคุมว่าข้อมูลใดที่ผู้ใช้อื่นอาจเห็นจากบัญชีผู้ใช้ของท่าน
        ท่านสามารถเปลี่ยนแปลงข้อมูลหรือตั้งค่าได้เมื่อท่านล็อคอินเข้าสู่ระบบ
      </dd>
      <h2>5. การเข้าสู่เว็บเพจ</h2>
      <dd>
        5.1. ในการเข้าถึงเว็บไซต์ https://aiya.ai
        อุปกรณ์ของท่านจะส่งข้อมูลซึ่งอาจรวมถึงข้อมูลส่วนบุคคลของท่านมายังเว็บไซต์ของบริษัท
        และข้อมูลดังกล่าวจะถูกบันทึกไว้ในเซิร์ฟเวอร์ของเว็บไซต์
        ซึ่งข้อมูลที่ส่งมายังเว็บไซต์จะรวมถึง ที่อยู่ IP ของคอมพิวเตอร์ ระบบปฏิบัติการ
        ชื่อ/เวอร์ชันของเบราว์เซอร์ เว็บเพจที่อ้าง หน้าที่ร้องขอ วันที่/เวลา และบางครั้งอาจรวมถึง
        "คุกกี้" เพื่อช่วยให้เว็บไซต์สามารถจดจำการเข้าชมครั้งล่าสุดของท่าน เมื่อท่านเข้าสู่ระบบ
        ข้อมูลนี้จะเชื่อมโยงกับบัญชีผู้ใช้ของท่าน
        นอกจากนี้ข้อมูลนี้จะนำไปใช้ในเชิงสถิติที่ไม่ระบุชื่อเพื่อช่วยให้บริษัทสามารถทำความเข้าใจเกี่ยวกับวิธีที่ผู้เยี่ยมชมใช้งาน
        AIYA แชทบอท ของบริษัท
      </dd>
      <h2>6. คุกกี้</h2>
      <dd>
        6.1. บริษัทอาจมีการใช้ “คุกกี้” หรือรูปแบบอื่น ๆ
        เพื่อช่วยให้บริษัทหรือบุคคลภายนอกสามารถเก็บรวบรวมหรือแบ่งปันข้อมูล เพื่อช่วยบริษัทปรับปรุง
        AIYA แชทบอท และบริการที่บริษัทนำเสนอ หรือช่วยให้บริษัทมอบบริการลักษณะใหม่ๆ ให้ท่านได้
        “คุกกี้” คือตัวระบุต่าง ๆ ที่บริษัทส่งไปยังอุปกรณ์ของท่าน
        เพื่อช่วยให้บริษัทสามารถจดจำอุปกรณ์ของท่าน
        และบอกเกี่ยวกับวิธีและเวลาในการใช้หรือเยี่ยมชมบริการ หรือจำนวนผู้ใช้
        และเพื่อติดตามความเคลื่อนไหวภายในเว็บไซต์ของบริษัท ทั้งนี้
        บริษัทอาจเชื่อมโยงข้อมูลคุกกี้กับข้อมูลส่วนบุคคลของท่าน นอกจากนี้
        ยังมีการใช้คุกกี้เพื่อเสนอเนื้อหาที่เหมาะสมกับความสนใจของท่านโดยเฉพาะ
        และเพื่อตรวจสอบการใช้งาน AIYA แชทบอท
        <br />
        6.2. ท่านอาจปฏิเสธการใช้คุกกี้ได้โดยเลือกการตั้งค่าที่เหมาะสมในอุปกรณ์ของท่าน
        อย่างไรก็ดีหากท่านปฏิเสธการใช้คุกกี้ ท่านอาจไม่สามารถใช้ฟังก์ชันการทำงานของเว็บไซต์
        หรือใช้บริการ AIYA แชทบอทได้อย่างเต็มประสิทธิภาพ
      </dd>
      <h2>7. การสนับสนุนในการให้บริการลูกค้า</h2>
      <dd>
        7.1. บริษัทอาจจะดำเนินการติดต่อท่านผ่านอีเมลหรือ SMS หรือผ่านแบบฟอร์มขอความคิดเห็น
        บริษัทจึงอาจต้องขออีเมลและหมายเลขติดต่อของท่าน โดยบริษัทจะใช้เพียงข้อมูลที่ได้รับจากท่าน
        ในกรณีที่ท่านยินดีให้การสนับสนุน
        และบริษัทจะไม่ส่งหรือแบ่งปันข้อมูลที่ได้รับให้กับบุคคลภายนอก
      </dd>
      <h2>8. วัตถุประสงค์ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน</h2>
      <dd>
        8.1. บริษัทอาจเก็บรวบรวม ใช้ เปิดเผย
        และ/หรือดำเนินการกับข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อย่างน้อยหนึ่งวัตถุประสงค์ดังต่อไปนี้
        <dd>
          8.1.1. เพื่อพิจารณาและ/หรือดำเนินการสำหรับการยื่นเรื่อง/ธุรกรรมของท่านกับบริษัท
          หรือธุรกรรมหรือการติดต่อสื่อสารของท่านกับบุคคลภายนอกผ่านบริการของบริษัท
          <br />
          8.1.2. เพื่อจัดการ ดำเนินการ ให้ และ/หรือบริหารการใช้งานของท่านและ/หรือเพื่อเข้าถึงบริการ
          AIYA แชทบอท ของบริษัท รวมถึงความสัมพันธ์และบัญชีผู้ใช้ของท่านกับบริษัท
          <br />
          8.1.3. เพื่อจัดการ ดำเนินการ บริหาร และจัดหาบริการแก่ท่าน
          รวมถึงช่วยอำนวยความสะดวกในการจัดเตรียมบริการของบริษัท เช่น การจดจำความต้องการของท่าน
          <br />
          8.1.4. เพื่อปรับแต่งประสบการณ์ของท่านผ่านบริการของบริษัท
          โดยแสดงเนื้อหาตามความสนใจและความต้องการของท่าน
          พร้อมให้วิธีที่รวดเร็วในการเข้าถึงบัญชีของท่านและส่งข้อมูลมายังบริษัท
          ซึ่งช่วยให้บริษัทสามารถติดต่อกับท่านได้หากจำเป็น
          <br />
          8.1.5. เมื่อท่านใช้บริการ AIYA
          แชทบอทหรือบริการอื่นของบุคคลภายนอกที่ใช้หรือรวมกับบริการของบริษัท
          บุคคลภายนอกนั้นอาจรับข้อมูลเกี่ยวกับสิ่งที่ท่านเสนอ เผยแพร่ หรือแบ่งปัน
          <br />
          8.1.6.
          เพื่อบังคับใช้เงื่อนไขการให้บริการหรือข้อตกลงสิทธิ์การใช้งานสำหรับผู้ใช้ที่เกี่ยวข้อง
          <br />
          8.1.7. เพื่อป้องกันความปลอดภัยและสิทธิ์ ทรัพย์สินส่วนบุคคล หรือความปลอดภัยของบุคคลอื่น
          <br />
          8.1.8. เพื่อระบุตัวตนและ/หรือการตรวจสอบความถูกต้อง
          <br />
          8.1.9. เพื่อรักษาและควบคุมการอัปเดตซอฟต์แวร์และ/หรือการอัปเดตอื่น ๆ
          และให้การสนับสนุนที่จำเป็นในบางคราวเพื่อให้แน่ใจว่าบริการของบริษัทจะเป็นไปอย่างราบรื่น
          <br />
          8.1.10. เพื่อจัดการหรืออำนวยความสะดวกในการให้บริการลูกค้า ปฏิบัติตามคำสั่งของท่าน
          จัดการหรือตอบข้อซักถามที่ได้รับ (หรืออ้างว่าได้รับ) จากท่านหรือในนามของท่าน
          <br />
          8.1.11. เพื่อติดต่อหรือสื่อสารกับท่านผ่านการโทรด้วยเสียง ข้อความ และ/หรือข้อความแฟกซ์
          อีเมล และ/หรือการส่งไปรษณีย์ หรือในรูปแบบอื่นใด
          เพื่อวัตถุประสงค์ในการบริหารและ/หรือจัดการความสัมพันธ์ระหว่างท่านกับบริษัทหรือการใช้บริการของบริษัท
          <br />
          8.1.12.
          เพื่อแจ้งให้ท่านทราบเมื่อผู้ใช้คนอื่นส่งข้อความส่วนตัวหรือแสดงความคิดเห็นต่อท่านผ่านช่องทางต่าง
          ๆ บนเว็บไซต์หรือ AIYA แชทบอท
          <br />
          8.1.13. เพื่อดำเนินการศึกษาวิจัย วิเคราะห์ และพัฒนากิจกรรมต่าง ๆ
          เพื่อวิเคราะห์การใช้บริการ เพื่อนำไปปรับปรุงบริการหรือผลิตภัณฑ์ของบริษัท
          และ/หรือช่วยเสริมประสบการณ์ของลูกค้า
          <br />
          8.1.14. เพื่อให้สามารถตรวจสอบและทำการสำรวจหรือดำเนินการอื่นใด
          เพื่อตรวจสอบขนาดและองค์ประกอบของกลุ่มเป้าหมายของบริษัท
          และทำความเข้าใจเกี่ยวกับประสบการณ์ที่ลูกค้าได้รับจากบริการของบริษัท
          <br />
          8.1.15. เพื่อส่งข้อมูลการตลาดและการส่งเสริมการขาย หรือโปรโมชัน หรือส่วนลดของ AIYA แชทบอท
          ให้แก่ท่านในกรณีที่ท่านให้ความยินยอมให้เราส่งข้อมูลการตลาดและการส่งเสริมการขาย
          และเอกสารที่เกี่ยวข้องกับผลิตภัณฑ์และ/หรือบริการ
          (รวมถึงแต่ไม่จำกัดเพียงผลิตภัณฑ์และ/หรือบริการของบุคคลที่สามซึ่งบริษัทอาจทำงานร่วมกันหรือมีข้อผูกพันต่อกัน)
          เพื่อการตลาดและในเรื่องดังกล่าวนี้ โดยผ่านรูปแบบการติดต่อสื่อสารต่าง ๆ ได้แก่
          การส่งไปรษณีย์ การบริการตามตำแหน่งที่ตั้งหรือรูปแบบอื่นใด ซึ่งบริษัทอาจจำหน่าย ทำการตลาด
          หรือส่งเสริมการขาย
          ไม่ว่าผลิตภัณฑ์หรือบริการดังกล่าวจะมีอยู่ในขณะนี้หรือจะเกิดขึ้นในอนาคตหรือไม่ก็ตาม
          <br />
          8.1.16. เพื่อใช้ในกรณีที่จำเป็นในกระบวนการทางกฎหมาย
          หรือปฏิบัติตามหรือเป็นไปตามกฎหมายที่บังคับใช้
          ข้อกำหนดของภาครัฐหรือตามกฎข้อบังคับภายใต้อำนาจการพิจารณาที่เกี่ยวข้อง เช่น
          การดูแลให้เป็นไปตามข้อกำหนดเพื่อทำให้การเปิดเผยข้อมูลนั้นเป็นไปตามข้อกฎหมายที่บริษัท
          หรือบริษัทที่เกี่ยวข้องหรือบริษัทในเครือต้องปฏิบัติตาม
          <br />
          8.1.17. เพื่อจัดทำข้อมูลสถิติและงานวิจัยเพื่อการรายงานภายในองค์กรและตามกฎหมาย
          และ/หรือตามข้อกำหนดเกี่ยวกับการจัดเก็บบันทึก
          <br />
          8.1.18.
          เพื่อปฏิบัติตามขั้นตอนการตรวจสอบประวัติความเป็นมาทางธุรกิจหรือกิจกรรมการคัดกรองอื่นใดซึ่งเป็นไปตามมาตรฐานของบริษัท
          หรือข้อผูกพันทางกฎหมายหรือตามกฎข้อบังคับ
          หรือกระบวนการจัดการความเสี่ยงของบริษัทซึ่งกฎหมายได้กำหนดไว้หรือที่บริษัทได้กำหนดไว้เป็นแนวทางปฏิบัติ
          <br />
          8.1.19. เพื่อตรวจสอบบริการของบริษัท
          <br />
          8.1.20. เพื่อป้องกันและตรวจสอบ
          การละเมิดหรือสงสัยว่าจะเป็นการกระทำละเมิดเกี่ยวกับข้อกำหนดในเงื่อนไขการให้บริการของบริษัท
          การฉ้อโกง กิจกรรมที่ผิดกฎหมาย การละเว้นหรือการประพฤติผิด
          ไม่ว่าจะเกี่ยวข้องกับการใช้บริการของบริษัทหรือกิจกรรมอื่นใดอันเกิดจากความสัมพันธ์ของท่านกับบริษัท
          <br />
          8.1.21. เพื่อจัดเก็บ โฮสต์ สำรองข้อมูล สำหรับข้อมูลส่วนบุคคลของท่าน
          ไม่ว่าจะเพื่อการกู้คืนจากความเสียหาย หรือเหตุอื่นใด หรือเพื่อกระทำตามความเห็น คำแนะนำ
          หรือคำสั่งจากหน่วยงานกำกับดูแลที่เกี่ยวข้อง
          <br />
          8.1.22. วัตถุประสงค์อื่นใดที่บริษัทอาจแจ้งแก่ท่าน ณ เวลาที่ได้รับความยินยอมจากท่าน
        </dd>
        8.2. เนื่องจากวัตถุประสงค์บางประการที่บริษัทจะเก็บรวบรวม ใช้ เปิดเผย
        และ/หรือดำเนินการข้อมูลส่วนบุคคลของท่านขึ้นอยู่กับสถานการณ์เฉพาะหน้า
        วัตถุประสงค์ดังกล่าวอาจไม่ปรากฏในข้างต้น อย่างไรก็ตาม
        บริษัทจะแจ้งให้ท่านทราบถึงวัตถุประสงค์ดังกล่าว ณ เวลาที่ขอรับความยินยอมจากท่าน
        เว้นแต่การดำเนินการข้อมูลที่เกี่ยวข้องโดยไม่ได้รับความยินยอมจากท่านนั้นเป็นไปตามที่กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลกำหนดไว้
      </dd>
      <h2>9. มาตรการคุ้มครองและเก็บรักษาข้อมูลส่วนบุคคลของท่าน</h2>
      <dd>
        9.1. บริษัทได้มีการนำมาตรการด้านความปลอดภัยต่าง ๆ
        ที่หลากหลายมาใช้ด้วยความพยายามอย่างดีที่สุด
        เพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านที่อยู่ในระบบของบริษัทนั้นมีความปลอดภัย
        และข้อมูลส่วนบุคคลของผู้ใช้จะถูกเก็บไว้ในเครือข่ายที่มีความปลอดภัย
        และสามารถเข้าถึงได้โดยพนักงานจำนวนจำกัดเท่านั้น
        ซึ่งจะเป็นบุคคลที่มีสิทธิพิเศษในการเข้าถึงระบบดังกล่าว อย่างไรก็ตาม
        บริษัทไม่สามารถรับประกันความปลอดภัยของข้อมูลส่วนบุคคลของท่านได้หากเกิดความบกพร่องจากกรณีเหตุสุดวิสัย
        หรือเมื่อเกิดการเปิดเผยข้อมูลโดยไม่ได้รับอนุญาต
        ซึ่งอาจเกิดจากการพยายามเข้าถึงข้อมูลจากผู้ที่ประสงค์ร้ายที่มีความซับซ้อนอย่างมากโดยที่ไม่ได้มีสาเหตุมาจากบริษัท
        <br />
        9.2.
        บริษัทจะรักษาข้อมูลส่วนบุคคลของท่านตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและ/หรือกฎหมายอื่นที่เกี่ยวข้อง
        ซึ่งบริษัทจะทำลายหรือไม่ระบุชื่อข้อมูลส่วนบุคคลของท่านทันทีที่บริษัทมีเหตุผลอันเชื่อได้ว่า
        <dd>
          9.2.1.
          วัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคลนั้นไม่ตรงกับการรักษาข้อมูลส่วนบุคคลดังกล่าวไว้อีกต่อไป
          <br />
          9.2.2.
          ไม่จำเป็นต้องรักษาข้อมูลส่วนบุคคลดังกล่าวเพื่อวัตถุประสงค์ทางกฎหมายหรือธุรกิจอีกต่อไป
          <br />
          9.2.3.
          ไม่มีเหตุอันจะอ้างประโยชน์อันชอบธรรมในการเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวได้อีกต่อไป
        </dd>
        9.3. ในกรณีที่ท่านหยุดการใช้งาน AIYA แชทบอท หรือสิทธิการใช้งาน AIYA แชทบอท
        และ/หรือบริการของท่านถูกเพิกถอนหรือสิ้นสุดลง บริษัทอาจจัดเก็บ ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลต่อไปได้ตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและข้อผูกพันของเราภายใต้กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลนี้
        ภายใต้กฎหมายที่บังคับใช้บริษัทอาจทำลายข้อมูลส่วนบุคคลของท่านอย่างปลอดภัยโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
      </dd>
      <h2>10. การเปิดเผยข้อมูลที่รวบรวมให้แก่บุคคลภายนอก</h2>
      <dd>
        10.1. ในการดำเนินธุรกิจของบริษัท
        บริษัทอาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่ผู้ให้บริการซึ่งเป็นบุคคลภายนอก ตัวแทน
        และ/หรือบริษัทในเครือ และ/หรือบริษัทที่เกี่ยวข้องกับบริษัท และ/หรือบุคคลที่สามอื่น ๆ
        เพื่อวัตถุประสงค์ดังที่ระบุข้างต้นอย่างน้อยหนึ่งวัตถุประสงค์ ผู้ให้บริการซึ่งเป็นบุคคลภายนอก
        ตัวแทน และ/หรือบริษัทในเครือ และ/หรือบริษัทที่เกี่ยวข้องกับบริษัท และ/หรือบุคคลภายนอกอื่น ๆ
        ดังกล่าวนั้นอาจดำเนินการข้อมูลส่วนบุคคลของท่านในนามของบริษัทหรือในลักษณะอื่นใดเพื่อวัตถุประสงค์ดังที่ระบุข้างต้นอย่างน้อยหนึ่งวัตถุประสงค์
        <br />
        10.2. บริษัทอาจแบ่งปัน
        ข้อมูลเชิงสถิติและประชากรศาสตร์เกี่ยวกับผู้ใช้และการใช้บริการของผู้ใช้ของบริษัทให้แก่ผู้จัดหาโฆษณาและผู้เขียนโปรแกรม
        โดยข้อมูลนี้จะไม่รวมข้อมูลใด ๆ ที่อาจนำไปใช้เพื่อระบุตัวตนของท่านโดยเฉพาะ
        หรือเพื่อค้นข้อมูลส่วนบุคคลเกี่ยวกับตัวท่าน
        <br />
        10.3. เพื่อไม่ให้เกิดข้อสงสัย
        ในกรณีที่กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายที่เกี่ยวข้องอื่น ๆ
        อนุญาตให้องค์กรใด ๆ เช่น องค์กรของบริษัท เก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านโดยไม่ต้องได้รับความยินยอมจากท่าน
        ให้นำการอนุญาตโดยกฎหมายข้างต้นมาใช้บังคับต่อไป
      </dd>
      <h2>11. ข้อมูลของเด็กและผู้เยาว์</h2>
      <dd>
        11.1. บริการนี้ไม่ได้มุ่งหมายไว้ให้บริการสำหรับเด็กที่มีอายุต่ำกว่า 15 ปี บริษัทจะไม่รวบรวม
        หรือเก็บรักษาข้อมูลส่วนตัวใด ๆ
        หรือข้อมูลที่ไม่เป็นส่วนตัวที่สามารถระบุยืนยันตัวตนได้จากผู้ซึ่งมีอายุต่ำกว่า 15 ปี
        หรือจัดให้ส่วนหนึ่งส่วนใดของ AIYA แชทบอท หรือบริการอื่น ๆ
        ของบริษัทแสดงแก่เด็กที่มีอายุต่ำกว่า 15 ปี โดยตั้งใจ ทั้งนี้
        บริษัทขอให้บุคคลผู้เป็นบิดามารดาหรือผู้ปกครองตามกฎหมาย
        โปรดดูแลไม่ให้เด็กที่อยู่ภายใต้อำนาจปกครองของท่านส่งมอบข้อมูลส่วนตัวให้แก่บริษัท
        แต่ในกรณีที่มีการส่งมอบข้อมูลส่วนตัวของเด็กที่มีอายุต่ำกว่า 15
        ปีที่อยู่ภายใต้อำนาจปกครองของท่านให้แก่บริษัท ถือว่าท่านยินยอมให้บริษัท
        เก็บรวบรวมข้อมูลส่วนตัวนั้น
        และท่านยอมรับและตกลงที่จะผูกพันตามเงื่อนไขที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้ในนามของเด็กผู้นั้น
        อย่างไรก็ตาม บริษัทจะทำการปิดบัญชีที่เด็กใช้และจะถอนและ/หรือลบข้อมูลส่วนบุคคลใด ๆ
        ซึ่งเราเชื่อว่าถูกส่งมาจากเด็กที่มีอายุต่ำกว่า 15 ปี
        โดยปราศจากความยินยอมจากบุคคลผู้เป็นบิดามารดาหรือผู้ปกครองตามกฎหมาย
      </dd>
      <h2>12. การโอนข้อมูลส่วนบุคคลของท่านไปต่างประเทศ</h2>
      <dd>
        12.1. ข้อมูลส่วนบุคคลและ/หรือข้อมูลของท่านอาจถูกถ่ายโอน จัดเก็บ หรือดำเนินการภายนอกประเทศ
        เพื่อประโยชน์ในดำเนินการตามสัญญา หรือตามคำร้องขอ/คำขอให้บริการ/คำขอรับบริการของท่านบน AIYA
        แชทบอท
        โดยจะถ่ายโอนข้อมูลของท่านไปยังต่างประเทศตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลเท่านั้น
      </dd>
      <h2>13. กระบวนการในการถอนความยินยอม ร้องขอการเข้าถึง หรือแก้ไขข้อมูลของท่าน</h2>
      <dd>
        13.1. หากท่านต้องการเปลี่ยนแปลงแก้ไขการสมัครใช้งานทางอีเมล
        โปรดแจ้งให้บริษัททราบโดยการส่งอีเมลไปที่ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัท
        <br />
        13.2. หากท่านต้องการถอนความยินยอมของท่านเกี่ยวกับการเก็บรวบรวม ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านซึ่งอยู่ในครอบครองหรือควบคุมของบริษัท
        สามารถทำได้โดยการส่งอีเมลไปที่ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัท
        <br />
        13.3.
        เมื่อบริษัทได้รับคำสั่งหรือคำร้องขอเกี่ยวกับการถอนความยินยอมเป็นลายลักษณ์อักษรอย่างชัดเจนจากท่านและตรวจสอบข้อมูลประจำตัวของท่านแล้ว
        บริษัทจะดำเนินการตามคำขอในการถอนความยินยอมของท่าน
        ตามเงื่อนไขที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้
        และกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและกฎหมายอื่นที่เกี่ยวข้อง
        และหลังจากนั้นบริษัทจะไม่เก็บรวบรวม ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านในลักษณะตามที่ระบุไว้ในการร้องขอของท่าน
        หากบริษัทไม่สามารถตรวจสอบข้อมูลประจำตัวหรือไม่เข้าใจคำสั่งหรือคำร้องขอของท่าน
        บริษัทจะติดต่อเพื่อขอคำอธิบายเกี่ยวกับการร้องขอดังกล่าวจากท่าน
        <br />
        13.4. อย่างไรก็ตาม การถอนความยินยอมอาจทำให้เกิดผลอันเกิดจากการถอนดังกล่าวได้
        ในเรื่องดังกล่าวนี้
        ขึ้นอยู่กับขอบเขตของการถอนความยินยอมที่จะให้บริษัทดำเนินการกับข้อมูลส่วนบุคคลของท่าน
        และอาจหมายความว่าบริษัทจะไม่สามารถให้บริการแก่ท่านต่อไปได้
        ซึ่งบริษัทจะแจ้งให้ท่านทราบเป็นรายกรณี
        <br />
        13.5. หากท่านมีบัญชีผู้ใช้กับบริษัท
        ท่านอาจเข้าถึงและ/หรือแก้ไขข้อมูลส่วนบุคคลของท่านซึ่งอยู่ในความดูแลหรือการควบคุมของบริษัทได้ด้วยตนเองผ่านหน้าการตั้งค่าบัญชีที่อยู่ในเว็บไซต์
        <br />
        13.6. สำหรับการร้องขอของท่านเพื่อเข้าถึงข้อมูลส่วนบุคคลของตนเองนั้น
        เมื่อบริษัทมีข้อมูลของท่านอย่างเพียงพอที่จะดำเนินการแล้ว
        บริษัทจะให้ข้อมูลส่วนบุคคลที่เกี่ยวข้องแก่ท่านภายใน 30 วัน
        หากบริษัทไม่สามารถตอบสนองการร้องขอของท่านภายใน 30 วันดังกล่าว
        บริษัทจะแจ้งให้ท่านทราบเกี่ยวกับระยะเวลาที่เร็วที่สุดที่บริษัทสามารถให้ข้อมูลที่ท่านร้องขอได้
      </dd>
      <h2>14. การร้องเรียนเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล</h2>
      <dd>
        14.1. หากท่านมีคำถามหรือข้อกังวลเกี่ยวกับข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของบริษัท
        หรือมีคำร้องเรียน หรือข้อข้องใจ เกี่ยวกับวิธีที่บริษัทจัดการข้อมูลส่วนบุคคลของท่าน
        หรือเกี่ยวกับวิธีที่บริษัทปฏิบัติตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการ
        <br />
        ติดต่อบริษัทได้ทันทีที่: บริษัท มีจีเนียส จำกัด อยู่ที่ 188 อาคารสปริงทาวเวอร์ (วีเวิร์ค
        ชั้น11) ถนนพญาไท แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพมหานคร รหัสไปรษณีย์ 10400
        หรือติดต่อบริษัทผ่านทางอีเมล ได้ที่: hello@aiya.ai
        <br />
        โดยให้ส่งถึง “เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Personal Data Protection Officer)”
        <br />
        14.2. ในกรณีที่ท่านใช้อีเมลหรือจดหมายเพื่อส่งคำร้องเรียนของท่าน
        โปรดระบุที่ส่วนหัวเรื่องว่าเป็นคำร้องเรียนเกี่ยวกับกฎหมายคุ้มครองข้อมูลส่วนบุคคล
        ซึ่งจะช่วยให้บริษัทดำเนินการเกี่ยวกับคำร้องเรียนของท่านโดยเร่งด่วนได้
        โดยส่งต่อไปยังเจ้าหน้าที่ที่เกี่ยวข้องในบริษัทเพื่อดำเนินการต่อไป
        โดยบริษัทจะพยายามดำเนินการกับคำร้องเรียนหรือข้อข้องใจใด ๆ
        ที่ท่านประสบอยู่อย่างเป็นธรรมและโดยเร็วที่สุดเท่าที่จะทำได้
      </dd>
    </div>
  )
}

export default Privacy
